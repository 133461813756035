import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#prechladnutie"> &gt; Radca(PRECHLADNUTIE)</a>
            <a
              href="/radca/prechladnutie/ako_sa_ochranit_pred_prechladnutim/"
              className="sel"
            >
              {" "}
              &gt; Ako sa ochrániť pred prechladnutím?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/88688757.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Ako sa ochrániť pred prechladnutím?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Jesenný chlad a vyššia vlhkosť vzduchu v zime, jarná únava,
                    studený letný vietor – celý rok sme vystavení faktorom,
                    ktoré oslabujú{" "}
                    <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                      imunitný systém
                    </a>
                    , zhoršujú náš tepelný komfort a spôsobujú prienik vírusov
                    do organizmu. Pomocou niekoľkých jednoduchých riešení tomu
                    možno zabrániť.{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Medicínsky výskum preukázal, že existuje viac ako 200 častých
                  vírusov chrípky a prechladnutia. Neustále sa však objavujú
                  nové a tie známe mutujú. Organizmus preto treba dobre
                  pripraviť, aby sa naučil zvládnuť každého „útočníka“. Ochranu
                  možno zabezpečiť uplatňovaním niekoľkých zásad: obliekať sa
                  vhodne podľa počasia, správne vyvážiť stravu, športovať a piť
                  veľa vody.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Pri premenlivom počasí treba zaistiť správnu tepelnú izoláciu
                  tela. Dôležité je obliekať sa vhodne podľa aktuálnych
                  podmienok. Ak vonku fúka, prší alebo teplomer ukazuje hodnoty
                  pod nulou, najlepšie bude obliecť si viacero vrstiev –
                  naobliekať sa ako „cibuľa“. Keď nám bude teplo, jednu vrstvu
                  si vyzlečieme, aby sme sa neprehriali.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Posilňujte imunitný systém bez ohľadu na ročné obdobie a v prípade infekcie použite liek OLYNTH®."
                    src="/web/kcfinder/uploads/images/shutterstock_1107896966.jpg"
                    style={{
                      width: "380px",
                      height: "243px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "left",
                      margin: "5px 10px 10px 0px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Pre obranyschopnosť organizmu je dôležitá aj správna strava –
                  zdravá, bohatá na vitamíny, minerály a flavonoidy. Vďaka
                  ovociu a zelenine (najmä oranžovým a červeným druhom, ako
                  mrkva, paprika, tekvica alebo paradajky) prijímame
                  antioxidanty a karotenoidy, ktoré majú vysoký obsah vitamínu A
                  a predchádzajú chorobám. Surová kapusta a šaláty z nej sú
                  veľkou dávkou vitamínu C, ktorý významne posilňuje{" "}
                  <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                    imunitný systém
                  </a>
                  . Orechy, klíčky alebo rastlinný olej zas obsahujú
                  antioxidanty prospešné v boji s{" "}
                  <a href="/slovnik_pojmov/#infekcia" target="_blank">
                    infekciami
                  </a>
                  . Strava musí byť správne vyvážená a jesť sa musí pravidelne.
                  Posilňuje sa tým{" "}
                  <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                    imunitný systém
                  </a>
                  .
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Najlepšou zbraňou proti prechladnutiu je však telesná
                  aktivita, ktorá v sebe skrýva mnoho výhod. Pohyb, čerstvý
                  vzduch a fyzická činnosť utužujú organizmus, vďaka čomu sme
                  menej náchylní na ochorenia. Dobrá fyzická kondícia prináša aj
                  lepšiu náladu, vyššiu hladinu endorfínov a zdravšie telo.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  V rámci prevencie prechladnutia nesmieme zabúdať ani na
                  odpočinok. Spánok je spojenec zdravia. Každý z nás by mal
                  spávať 7 až 9 hodín denne, aby sa organizmus stihol
                  zregenerovať, posilniť a načerpať nové sily.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Štatisticky každého z nás raz ročne postihne{" "}
                  <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                    prechladnutie
                  </a>
                  .&nbsp;Keď už sa objaví{" "}
                  <a href="/slovnik_pojmov/#infekcia" target="_blank">
                    infekcia
                  </a>
                  , je preto vhodné zabezpečiť zmiernenie prvých príznakov a
                  predísť jej ďalšiemu šíreniu. Optimálne je urobiť tak už v
                  prvej fáze vzniku infekcie.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Príčinou infekcie, ktorá sa prejavuje nádchou, môže byť
                  napríklad kontakt s chorými, pobyt v nevetraných
                  miestnostiach, oslabenie organizmu, kolísanie teploty alebo
                  oblečenie neprispôsobené poveternostným podmienkam. V
                  situácii, kedy sa trápime s upchatým nosom a zahlienením, je
                  vhodné zmierňovať tieto príznaky pomocou liekov uvoľňujúcich
                  nos.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <strong>
                    <a href="/upravte_liek/" target="_blank">
                      Urobte si test a vyberte si správny liek
                    </a>
                  </strong>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Je vhodné siahnuť po nosovej roztokovej aerodisperzii{" "}
                  <a href="/lieky/olynth_01/" target="_blank">
                    OLYNTH<sup>®</sup> 0,1 %
                  </a>{" "}
                  alebo{" "}
                  <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %
                  </a>
                  , ktorá vďaka obsahu{" "}
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    xylometazolínu
                  </a>
                  &nbsp;rýchlo uvoľňuje nos. Správna strava, pohyb a odpočinok
                  pripravia náš organizmus na boj s vírusmi a posilnia jeho
                  obranyschopnosť, takže sa telo bude môcť účinnejšie brániť
                  pred infekciami.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Tylżanowska-Kisiel Joanna,{" "}
                        <em>Przeziębienie i grypa</em>, Helion, 2007.
                      </span>
                    </span>
                  </li>
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Górnicka Jadwiga,{" "}
                        <em>Nie daj się przeziębieniu! Receptury natury</em>,
                        AWM Morex, 2014.{" "}
                      </span>
                    </span>
                  </li>
                  <li className="li3">
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Dr n. med. Filip Mejza,{" "}
                        <em>
                          Przeziebienie, Medycyna Praktyczna dla pacjentów
                        </em>
                        , [online] http://grypa.mp.pl/przeziebienie/61668,
                        przeziebienie
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg)"}} />
                  <span className="title">
                    Domáce spôsoby boja s prechladnutím
                  </span>
                </a>
                <a
                  href="/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_87834502.jpg)"}} />
                  <span className="title">Štyri fázy vzniku prechladnutia</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
